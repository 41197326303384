<template>
    <v-container fluid>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Titulo</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="news.title" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Contenido</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-textarea label="" v-model="news.content" required outlined dense rows="12" row-height="15">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Foto</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <InputPhotoUpload :item="news" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Fecha</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-menu v-model="news.menu" :close-on-content-click="false" readonly
                            transition="scale-transition" max-width="290px" min-width="290px">
                            <template #activator="{ on, attrs }">
                                <v-text-field ref="publication_date" v-model="news.publication_date"
                                    append-icon="mdi-calendar" outlined label="" readonly dense persistent-hint
                                    v-on="on" v-bind="attrs" />
                            </template>
                            <v-date-picker v-model="news.date" no-title @input="
    news.menu = false;
news.publication_date = formatDate(news.date);
                            " />
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Hora</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-menu ref="menu" v-model="news.menu2" :close-on-content-click="false" readonly
                            transition="scale-transition" :return-value.sync="news.publication_time" offset-y
                            max-width="290px" min-width="290px">
                            <template #activator="{ on, attrs }">
                                <v-text-field ref="publication_time" v-model="news.publication_time"
                                    append-icon="mdi-clock-time-four-outline" outlined label="" readonly dense
                                    persistent-hint v-on="on" v-bind="attrs" />
                            </template>
                            <v-time-picker v-if="news.menu2" v-model="news.publication_time" full-width
                                @click:minute="$refs.menu.save(news.publication_time)"></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Fuente</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="news.source" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

// import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import mixins from "@/mixins";
import InputPhotoUpload from "../util/InputPhotoUpload.vue";

export default {
    name: "form-news",
    props: ["news"],
    components: { InputPhotoUpload },
    watch: {},
    validations() {
        return {
            news: {
                title: required,
                // fifa_position: required,
                // continent_id: required,
                // games_won: required,
                // games_lost: required,
                // games_tied: required,
                // goals: required,
                // players: required,
            },
        };
    },
    data: () => ({
    }),
    computed: {
        // ...mapState({
        //     selectTeams: (state) => state.team.selectTeams,
        // }),
    },
    methods: {
        // ...mapActions(["fetchAllTeams"]),
        getFetch() {
            // this.fetchAllTeams();
        },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style scoped>
/* .row {
    margin-top: -10px !important;
} */
</style>