<template>
  <v-container fluid>
    <v-col col="12" md="12" lg="12" outlined>
      <v-card class="pa-4 rounded-xl">
        <v-card-title class="justify-left d-flex flex-row pb-1">
          <p>Noticia</p>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider color="#75D89F" class="mx-3"></v-divider>
        <v-card-text>
          <!-- <h3 style="color:#083C5F" class="pb-4">Información del Grupo</h3> -->
          <v-row>
            <v-col col="12">
              <form-news :news="inews"></form-news>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-btn @click="save()" class="ma-3 rounded-lg text-capitalize" dense color="primary">
             Grabar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text }}</span>
    </v-snackbar>
  </v-container>
</template>



<script>
import FormNews from "@/components/forms/FormNews.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: "NewsAddEdit",
  props: ["id"],
  components: {
    FormNews,
  },
  watch: {
    news: function (value) {
      // console.log(value)
      if (this.id) {
        this.inews = _cloneDeep(value);
      } 
    },
    newsError: function (value) {
      if (value)
        this.mensaje(true, "error", this.newsError.message, 5000);
    },
    newsSusccesMessage: function (value) {
      if (value) {
        this.mensaje(true, "success", this.newsSusccesMessage, 5000);
        if (!this.id) this.inews = {};
        this.$router.go(-1);
      }
    },

  },
  computed: {
    ...mapState({
      news: (state) => state.news.news,
      newsError: (state) => state.news.newsError,
      newsSusccesMessage: (state) => state.news.newsSusccesMessage,
    }),
  },
  data: () => ({
    inews: {
    },
    snackbar: false,
    color: "",
    text: "",
  }),
  methods: {
    ...mapActions(["addNews", "editNews", "getNewsById"]),
    save() {
      // console.log(this.inews);
      if (this.id) {
        //update
        this.editNews({ news: this.inews });
      } else {
        this.addNews({ news: this.inews });
        //create
      }
    },
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    getFetch() {
      // console.log(this.news)
      if (this.id) {
        this.getNewsById({ id: this.id });
      }
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style scoped>
</style>