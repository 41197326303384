var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-subheader',{staticClass:"d-flex justify-end"},[_vm._v("Titulo")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"","required":"","outlined":"","dense":""},model:{value:(_vm.news.title),callback:function ($$v) {_vm.$set(_vm.news, "title", $$v)},expression:"news.title"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-subheader',{staticClass:"d-flex justify-end"},[_vm._v("Contenido")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-textarea',{attrs:{"label":"","required":"","outlined":"","dense":"","rows":"12","row-height":"15"},model:{value:(_vm.news.content),callback:function ($$v) {_vm.$set(_vm.news, "content", $$v)},expression:"news.content"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-subheader',{staticClass:"d-flex justify-end"},[_vm._v("Foto")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('InputPhotoUpload',{attrs:{"item":_vm.news}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-subheader',{staticClass:"d-flex justify-end"},[_vm._v("Fecha")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"readonly":"","transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"publication_date",attrs:{"append-icon":"mdi-calendar","outlined":"","label":"","readonly":"","dense":"","persistent-hint":""},model:{value:(_vm.news.publication_date),callback:function ($$v) {_vm.$set(_vm.news, "publication_date", $$v)},expression:"news.publication_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.news.menu),callback:function ($$v) {_vm.$set(_vm.news, "menu", $$v)},expression:"news.menu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.news.menu = false;
_vm.news.publication_date = _vm.formatDate(_vm.news.date);}},model:{value:(_vm.news.date),callback:function ($$v) {_vm.$set(_vm.news, "date", $$v)},expression:"news.date"}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-subheader',{staticClass:"d-flex justify-end"},[_vm._v("Hora")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"readonly":"","transition":"scale-transition","return-value":_vm.news.publication_time,"offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.news, "publication_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.news, "publication_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"publication_time",attrs:{"append-icon":"mdi-clock-time-four-outline","outlined":"","label":"","readonly":"","dense":"","persistent-hint":""},model:{value:(_vm.news.publication_time),callback:function ($$v) {_vm.$set(_vm.news, "publication_time", $$v)},expression:"news.publication_time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.news.menu2),callback:function ($$v) {_vm.$set(_vm.news, "menu2", $$v)},expression:"news.menu2"}},[(_vm.news.menu2)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.news.publication_time)}},model:{value:(_vm.news.publication_time),callback:function ($$v) {_vm.$set(_vm.news, "publication_time", $$v)},expression:"news.publication_time"}}):_vm._e()],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-subheader',{staticClass:"d-flex justify-end"},[_vm._v("Fuente")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"label":"","required":"","outlined":"","dense":""},model:{value:(_vm.news.source),callback:function ($$v) {_vm.$set(_vm.news, "source", $$v)},expression:"news.source"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }